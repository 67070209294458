<div class="logo">
  <a routerLink="/" style="display: block; width: 100%;">
    <img [src]="whiteLabelLogo" alt="" srcset="">
  </a>
</div>
<!-- <ul nz-menu [nzTheme]="'light'" nzMode="horizontal"> -->
<!-- <li nz-menu-item [nzMatchRouter]="true" [hidden]="isScreenSm"> -->
<!-- <a nz-button nzType="link" [routerLink]="['/', zeroAuthLink]">Zero Auth</a> -->
<!-- </li> -->
<!-- <li nz-menu-item [nzMatchRouter]="true" [hidden]="isScreenSm"> -->
<!-- <a nz-button nzType="link" [routerLink]="['/', recurringPaymentLink]">Recurring Payment</a> -->
<!-- </li> -->
<!-- <li nz-menu-item [nzMatchRouter]="true" [hidden]="isScreenSm"> -->
<!-- <a nz-button nzType="link" [routerLink]="['/', fashionLink]">Fashion</a> -->
<!-- </li> -->
<!-- <li nz-menu-item [nzMatchRouter]="true" [hidden]="isScreenSm"> -->
<!-- <a nz-button nzType="link" [routerLink]="['/', flowersLink]">Flowers</a> -->
<!-- </li> -->
<!-- <li nz-menu-item style="float: right;" [nzMatchRouter]="true" [hidden]="!contactBtnShow"> -->
<!-- <a nz-button nzType="link" href="https://wlpayments.com/#contact" target="__blank"> -->
<!-- <i nz-icon nzType="mail" nzTheme="outline"></i> -->
<!-- Get in Touch -->
<!-- </a> -->
<!-- </li> -->
<!-- </ul> -->
