import { OrderService } from 'src/app/services/checkout.service';
import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CheckoutGuardService implements CanActivate {

  constructor(private orderService: OrderService, private router: Router) { }

  canActivate(): boolean {
    if (this.orderService.isCheckboxVisible === undefined) {
      this.router.navigate(['/']);
    }
    return true;
  }
}
