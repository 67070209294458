const basePageTitle = 'Paysafe Demo';

export const pageTitles = {
  base: basePageTitle,
  gaming: 'Gaming | ' + basePageTitle,
  crypto: 'Crypto | ' + basePageTitle,
  flowers: 'Flowers | ' + basePageTitle,
  fashion: 'Fashion | ' + basePageTitle,
  checkout: 'Checkout | ' + basePageTitle
};
