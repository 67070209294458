<app-loader [showBackdrop]="showBackdrop" [showInnerLoader]="showInnerLoader" [showSuccessLoader]="false"
  [showFailLoader]="false"></app-loader>
<div [style]="{'padding': '0 ' + ( gutterSize / 2 ) + 'px'}" class="checkout-container">
  <div nz-row nzAlign="middle" [nzGutter]="gutterSize">
    <div nz-col class="gutter-row" [nzSpan]="24">
      <h1 class="h1-responsive">Checkout Page</h1>
      <nz-divider></nz-divider>
    </div>
  </div>
  <div nz-row nzAlign="top" [nzGutter]="16">
    <div nz-col class="gutter-row p-20" [nzSpan]="colSize">
      <app-address-detail></app-address-detail>
    </div>
    <div nz-col class="gutter-row p-20" [nzSpan]="colSize">
      <app-payment-details details [cardVisibility]="true"
        (isPayButtonDisable)="payButtonHandler($event)"></app-payment-details>
    </div>
    <div nz-col class="gutter-row p-20" [nzSpan]="colSize">
      <app-order-details (fashionFinalPayment)="checkoutPayment($event)"
        [isPayButtonDisable]="isPayButtonDisable"></app-order-details>
    </div>
  </div>
  <nz-modal [@simpleFadeAnimation]="'in'" [(nzVisible)]="isIFrameModalVisible" nzWidth="410px" nzMaskClosable="false"
    nzWrapClassName="IFrameModal" nzTitle="The first Modal" (nzOnCancel)="closeModal()">
    <ng-container>
      <iframe src="" name="modalIFrame" frameborder="0" width="410" height="530">

      </iframe>
    </ng-container>
  </nz-modal>
</div>
