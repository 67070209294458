<div [@simpleFadeAnimation]="'in'" id="cover-spin" *ngIf="showBackdrop">
    <ul class="loader">
        <li class="center"></li>
        <li class="item item-1"></li>
        <li class="item item-2"></li>
        <li class="item item-3"></li>
        <li class="item item-4"></li>
        <li class="item item-5"></li>
        <li class="item item-6"></li>
        <li class="item item-7"></li>
        <li class="item item-8"></li>
    </ul>
</div>