import { Injectable } from '@angular/core';
// tslint:disable: variable-name

export interface PaymentCard {
  cardNumber: string;
  month: string;
  year: string;
  cvv: string;
  label: string;
}

export interface PaymentCardToken {
  tokenId: string;
}

export interface Order {
  name: string;
  price: number;
  quantity: number;
  color?: string;
}

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  private _isCheckboxVisible!: boolean;
  private _orderObject!: Order;
  addressObject = {
    firstName: 'John', lastName: 'Doe',
    addressLine1: '46 Wartnaby Road', addressLine2: 'BURNHEAD 150',
    mobileNo: '44-07034083599', emailId: 'gqx7pgta14@temporary-mail.net',
    city: 'ACTON', state: 'LONDON',
    zip: 'GL9 9XL', country: 'United Kingdom'
  };


  selectedCard!: PaymentCard;
  selectedToken!: string
  constructor() {
  }

  set orderObject(order: Order) {
    this._orderObject = order;
  }

  get orderObject(): Order {
    return this._orderObject;
  }

  set isCheckboxVisible(value: boolean) {
    this._isCheckboxVisible = value;
  }

  get isCheckboxVisible(): boolean {
    return this._isCheckboxVisible;
  }
}
