import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, ElementRef, Input, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { OrderService, PaymentCard } from 'src/app/services/checkout.service';
import { trigger, transition, style, animate } from '@angular/animations';
import { ICardType, PaymentService } from 'src/app/services/payment.service';



@Component({
  selector: 'app-payment-details',
  templateUrl: './payment-details.component.html',
  styleUrls: ['./payment-details.component.less'],
  animations: [trigger('slideDownUp', [
    transition(':enter', [style({ height: 0 }), animate(300)]),
    transition(':leave', [animate(300, style({ height: 0 }))]),
  ])
  ]
})
export class PaymentDetailsComponent implements OnInit, OnDestroy, AfterViewInit {

  colSize = 12;
  cryptoPage = false;
  // predefinedCards: PaymentCard[] = [];
  // allCards: any;
  tokenCards: ICardType[] = [];
  showPaymentDetails = true;
  selectedToken: string = ''
  private smBreakpointSubscription: Subscription;
  @Input() cardVisibility: boolean = true;
  payment = 'wlpayments';

  breakPointsObj = {
    sm: '(max-width: 600px)',
    md: '(max-width: 768px)',
    lg: '(max-width: 1024px)',
    xl: '(max-width: 1600px)'
  };
  companyName: string;
  whitelabelIcon: string;
  @Output() isPayButtonDisable: EventEmitter<boolean> = new EventEmitter();

  constructor(
    private breakpointObserver: BreakpointObserver,
    private orderService: OrderService,
    private paymentService: PaymentService
  ) {
    const whiteLabelConfig = (window as any).whitelabelConfig;
    this.companyName = whiteLabelConfig?.companyName || 'WLPayments';
    this.whitelabelIcon = whiteLabelConfig?.icons || '../../../favicon.ico';
    this.smBreakpointSubscription = this.breakpointObserver.observe(
      [this.breakPointsObj.sm, this.breakPointsObj.md, this.breakPointsObj.lg, this.breakPointsObj.xl]
    ).
      subscribe(result => {
        if (result.breakpoints[this.breakPointsObj.xl] && !result.breakpoints[this.breakPointsObj.lg]) {
          this.colSize = 24;
        }
        else if (result.breakpoints[this.breakPointsObj.lg] && !result.breakpoints[this.breakPointsObj.md]) {
          this.colSize = 8;
        }
        else if (result.breakpoints[this.breakPointsObj.md] && !result.breakpoints[this.breakPointsObj.sm]) {
          this.colSize = 11;
        }
        else if (result.breakpoints[this.breakPointsObj.sm]) {
          this.colSize = 24;
        }
        else {
          this.colSize = 12;
        }
      });
  }

  ngOnInit(): void {
    this.getToken();
  }

  getSelectedToken() {
    return this.selectedToken
  }

  ngAfterViewInit(): void {
    if (this.cardVisibility) {
      this.getPredefinedCards();
    }
  }

  getPredefinedCards(): void {

  }

  selectPaymentCard(index: number): void {
    BreakpointObserver
    this.selectedToken = this.tokenCards[index].id
    this.orderService.selectedToken = this.selectedToken;
    console.log(this.orderService.selectedToken);
  }

  togglePaymentDetails(): void {
    console.log('toggle payment details');
    this.showPaymentDetails = !this.showPaymentDetails ? false : true;
  }

  ngOnDestroy(): void {
    if (this.smBreakpointSubscription) {
      this.smBreakpointSubscription.unsubscribe();
    }
  }
  async getToken() {
    try {
      this.isPayButtonDisable.emit(true)
      await this.paymentService.getToken().subscribe(item => {
        console.log(item);
        if (item) {
          this.tokenCards = item.tokens;
          this.selectedToken = this.tokenCards.length > 0 ? this.tokenCards[0].id : ''
        } else {
          this.tokenCards = [];
          this.selectedToken = ''
        }
        this.isPayButtonDisable.emit(false);

      })
    }
    catch (err) {
      this.isPayButtonDisable.emit(true);

      this.tokenCards = [];
      this.selectedToken = ''

    }
  }
}
