import { HttpClient } from '@angular/common/http';
import { WhitelabelConfig } from './whitelabel';
import { WhitelabelService } from './services/whitelabel.service';

export const initializeAppFactory = (
    httpClient: HttpClient,
    whitelabelService: WhitelabelService,
) => {
    return () => httpClient.get(`/whitelabel.json`)
        .toPromise()
        .then((data: any) => {
            const config: WhitelabelConfig = { ...data };
            whitelabelService.setWhitelabelJSON(config);
            return config;
        })
        .catch(() => true);
};


export const Cards = {
    crypto: [
        { cardNumber: '5500000000000004', month: '07', year: '2027', cvv: '343', label: '5500-XXXX-XXXX-0004' },
        { cardNumber: '4200000000000000', month: '03', year: '2025', cvv: '345', label: '4200-XXXX-XXXX-0000' },
        { cardNumber: '5199992312641465', month: '02', year: '2028', cvv: '348', label: '5199-XXXX-XXXX-1465' },
        { cardNumber: '4539628347117863', month: '06', year: '2029', cvv: '341', label: '4539-XXXX-XXXX-7863' }
    ],
    gaming: [
        { cardNumber: '5204242750085996', month: '03', year: '2025', cvv: '576', label: '5204-XXXX-XXXX-5996' },
        { cardNumber: '4000000000001091', month: '03', year: '2026', cvv: '576', label: '4000-XXXX-XXXX-1091' },
        { cardNumber: '5455330200000016', month: '03', year: '2028', cvv: '576', label: '5455-XXXX-XXXX-0016' },
        { cardNumber: '4000000000001000', month: '03', year: '2029', cvv: '576', label: '4000-XXXX-XXXX-1000' }
    ],
    fashion: [
        { cardNumber: '5352151570003404', month: '03', year: '2025', cvv: '571', label: '5352-XXXX-XXXX-3404' },
        { cardNumber: '4485040371536584', month: '03', year: '2028', cvv: '571', label: '4485-XXXX-XXXX-6584' },
        { cardNumber: '5204242750085996', month: '03', year: '2028', cvv: '571', label: '5204-XXXX-XXXX-5996' },
        { cardNumber: '4000000000001059', month: '03', year: '2027', cvv: '571', label: '4000-XXXX-XXXX-1059' }
    ]
};
