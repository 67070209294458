import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { RequestTokenizationPayload, SDKInstance, TokenizationSuccessResponsePayload, initialize } from 'hosted-fields';
import { differenceInCalendarYears } from 'date-fns';
import { Subscription } from 'rxjs';
import { IGenerateHostedField, PaymentService } from 'src/app/services/payment.service';

@Component({
  selector: 'app-hosted-field',
  templateUrl: './hosted-field.component.html',
  styleUrls: ['./hosted-field.component.less']
})
export class HostedFieldComponent implements AfterViewInit, OnDestroy {

  @Input() customerId!: string
  @Input() cardHolderName!: string
  @Input() isSaveCardDisable!: boolean;

  @Output() isPayButtonDisable: EventEmitter<boolean> = new EventEmitter();
  @Output() isAddNewCardEnable: EventEmitter<boolean> = new EventEmitter();
  @Output() showBackdrop: EventEmitter<boolean> = new EventEmitter();
  @Output() cardToken: EventEmitter<Omit<TokenizationSuccessResponsePayload, 'status'>> = new EventEmitter();
  @ViewChild('skeletonLoader') skeletonLoader!: ElementRef;
  @ViewChild('mainContainer') mainContainer!: ElementRef;

  private hostedFieldToken!: IGenerateHostedField;
  private paymentEvent!: Subscription;

  expMonth!: string;
  expYear!: string;
  inputStyle!: any;
  lineHeight = '16px';
  saveCardFlag = true;
  sdkInstance!: SDKInstance;
  isLoading = true;
  years: number[];
  hfInpStyleKeys = [
    'color',
    'font-family',
    'font-size-adjust',
    'font-size',
    'font-stretch',
    'font-style',
    'font-variant-alternates',
    'font-variant-caps',
    'font-variant-east-asian',
    'font-variant-ligatures',
    'font-variant-numeric',
    'font-variant',
    'font-weight',
    'font',
    'letter-spacing',
    'line-height',
    'opacity',
    'text-align',
    'text-shadow',
  ];
  allowedStyleKeys = [
    ...this.hfInpStyleKeys,
    'appearance',
    'box-shadow',
    'direction',
    'outline',
    'margin',
    'margin-top',
    'margin-right',
    'margin-bottom',
    'margin-left',
    'padding',
    'padding-top',
    'padding-right',
    'padding-bottom',
    'padding-left',
    'transition',
    '-moz-appearance',
    '-moz-box-shadow',
    '-moz-osx-font-smoothing',
    '-moz-tap-highlight-color',
    '-moz-transition',
    '-webkit-appearance',
    '-webkit-box-shadow',
    '-webkit-font-smoothing',
    '-webkit-tap-highlight-color',
    '-webkit-transition',
    'placeholder-color',
  ]
  constructor(
    private paymentService: PaymentService
  ) {
    this.payNowEventHandler = this.payNowEventHandler.bind(this)
    const currentYear = new Date().getFullYear();
    const next20Years = [];
    for (let i = 0; i < 20; i++) {
      next20Years.push(currentYear + i);
    }
    this.years = next20Years;

  }
  ngOnDestroy(): void {
    this.paymentEvent?.unsubscribe()
  }

  ngAfterViewInit(): void {
    this.inputStyle = this.setStylesOnFields(getComputedStyle(document?.querySelector('#dummyElement') as Element));
    // jQuery('#card-number-container,#card-cvv-container').empty();
    this.getHostedFieldToken(this.getHostedFields.bind(this))
      .then(() => {
      })
      .catch(() => { this.isPayButtonDisable.next(true) });
  }


  async generateHostedFields() {
    try {
      await this.paymentService.generateFieldsHostedFieldID().subscribe(item => {
        this.hostedFieldToken = item;
        console.log(item);
      });
    }
    catch (err) {
      console.assert(err);
    }
  }

  async getHostedFieldToken(callback: () => any): Promise<any> {
    this.isPayButtonDisable.emit(true)
    return await this.paymentService.generateFieldsHostedFieldID().subscribe(item => {
      this.hostedFieldToken = item;
      callback();
    });

  }

  async getHostedFields() {
    try {
      const self = this;
      this.isLoading = true;
      const { url, requestID } = this.hostedFieldToken;
      const fields = {
        CARD_NUMBER: {
          containerId: 'card-number-container',
          placeholder: 'XXXX-XXXX-XXXX-XXXX',
          styles: self.inputStyle

        },
        CARD_CVV: {
          containerId: 'card-cvv-container',
          placeholder: 'XXX',
          styles: self.inputStyle
        },

      }

      this.sdkInstance = await initialize({
        iframeSrc: url,
        requestID: requestID,
        fields,
      })
      this.sdkInstance.showDetectedCardTypes();
      this.mainContainer.nativeElement.classList.remove('hide-frame');
      this.mainContainer.nativeElement.classList.add('show-frame');
      this.skeletonLoader.nativeElement.classList.remove('show-frame');
      this.skeletonLoader.nativeElement.classList.add('hide-frame');
      this.sdkInstance.on('inputSubmitRequest', function (event: any) {
        console.log(event)
      })
      this.isPayButtonDisable.emit(false)
    }
    catch (err) {
    }
    finally {
      this.isLoading = false;
    }
  }

  setStylesOnFields(styles: CSSStyleDeclaration) {
    const stylesObj: any = {};
    for (let index = 0; index < this.hfInpStyleKeys.length; index++) {
      const key = this.hfInpStyleKeys[index];
      const styleValue: string = styles.getPropertyValue(key);

      if ((key === 'display' && styleValue === 'none'))
        continue;
      if (styleValue) {
        stylesObj[key] = styleValue;
      }
    }
    return stylesObj;
  }

  async payNowEventHandler(): Promise<{
    tokenID?: string,
    requestID?: string,
  }> {
    try {
      this.isLoading = true
      const tokenObj: RequestTokenizationPayload = {
        saveCard: this.saveCardFlag ? 'true' : 'false',
        cardHolderName: this.cardHolderName,
        expMonth: this.expMonth,
        expYear: this.expYear?.toString(),
        isVirtualTerminal: true,
      }
      const reqToken = await this.sdkInstance.requestTokenization(tokenObj);
      return Promise.resolve(reqToken);
    }
    catch (err) {
      return Promise.reject();
    }

  }

  disabledYear(current: Date) {
    // Can not select year before today and today
    differenceInCalendarYears(current, new Date()) < 0;
  }

  closeAddNewCard() {
    this.isAddNewCardEnable.next(false);
  }
}
