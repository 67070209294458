<div class="card collection-card z-depth-1-half" (click)="onClickHandler()" [style.backgroundColor]=" bgColor">
  <!-- Card image -->
  <div class="view zoom">
    <img [src]="imgSrc ? imgSrc : '../../assets/sections/crypto.jpg'" class="img-fluid" alt="">
    <div class="stripe" [ngClass]="stripeTheme">
      <a>
        <p>{{storeTypeName}}
        </p>
      </a>
    </div>
  </div>
  <!-- Card image -->
</div>

<div [hidden]="true">
  <app-fashion></app-fashion>
</div>
