<div class="address-detail-container">
    <div class="billing-wrapper border p-20 pb-0">
        <h3 class="h3-responsive"><i nz-icon nzType="home" nzTheme="outline"></i> Billing Address</h3>
        <form nz-form [formGroup]="billingForm" class="mt-30">
            <!-- First Name and Last Name  -->
            <div nz-row nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please provide your first name." nzHasFeedback>
                                <input nz-input [nzSize]="inputSize" (input)="updateForm($event,'billing','firstName')" formControlName="firstName" placeholder="First Name" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please provide your last name." nzHasFeedback>
                                <input nz-input [nzSize]="inputSize" (input)="updateForm($event,'billing','lastName')" formControlName="lastName" placeholder="Last Name" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- First Name and Last Name  -->

            <!-- Address Lines  -->
            <nz-form-item>
                <nz-form-control nzErrorTip="Please fill out this address field" nzHasFeedback>
                        <input nz-input [nzSize]="inputSize" (input)="updateForm($event,'billing','addressLine1')" formControlName="addressLine1" placeholder="Address line 1" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please fill out this address field" nzHasFeedback>
                        <input nz-input [nzSize]="inputSize" (input)="updateForm($event,'billing','addressLine2')" formControlName="addressLine2" placeholder="Address line 2" />
                </nz-form-control>
            </nz-form-item>
            <!-- Address Lines  -->

            <!-- Phone Email  -->
            <div nz-row nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your Phone / Mobile No." nzHasFeedback>
                                <input nz-input [nzSize]="inputSize" formControlName="mobileNo" (input)="updateForm($event,'billing','mobileNo')" placeholder="Phone / Mobile No." />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your Email" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="emailId" (input)="updateForm($event,'billing','emailId')" placeholder="Email" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- Phone Email  -->

            <!-- City Zip  -->
            <div nz-row nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter name of your City" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="city" (input)="updateForm($event,'billing','city')" placeholder="City" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your zipcode" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="zip" (input)="updateForm($event,'billing','zip')" placeholder="Zip code" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- City Zip  -->

            <!-- State Country  -->
            <div nz-row nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your State" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="state" (input)="updateForm($event,'billing','state')" placeholder="State" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your Country" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="country" (input)="updateForm($event,'billing','country')" placeholder="Country" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- State Country -->
        </form>
        <!-- NOTE: unselect to enable the shipping address  -->
        <!-- <div class="checkbox-container mt-30 mb-20" *ngIf="shouldCheckboxVisible" style="cursor: pointer;">
            <label nz-checkbox (ngModelChange)="checkAddressDifference()" [(ngModel)]="isAddressDifferent">Check this box if Billing & Shipping Address are different.</label>
        </div> -->
    </div>
    <div @slideDownUp class="shipping-wrapper border p-20 pb-0 mt-20" *ngIf="isAddressDifferent">
        <h3 class="h3-responsive"><i nz-icon nzType="home" nzTheme="outline"></i> Shipping Address </h3>
        <form nz-form [formGroup]="shippingForm" class="mt-30">
            <!-- First Name and Last Name  -->
            <div nz-row nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please provide your first name." nzHasFeedback>
                                <input nz-input [nzSize]="inputSize" (input)="updateForm($event,'shipping','firstName')" formControlName="firstName" placeholder="First Name" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please provide your last name." nzHasFeedback>
                                <input nz-input [nzSize]="inputSize" (input)="updateForm($event,'shipping','lastName')" formControlName="lastName" placeholder="Last Name" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- First Name and Last Name  -->

            <!-- Address Lines  -->
            <nz-form-item>
                <nz-form-control nzErrorTip="Please fill out this address field" nzHasFeedback>
                        <input nz-input [nzSize]="inputSize" (input)="updateForm($event,'shipping','addressLine1')" formControlName="addressLine1" placeholder="Address line 1" />
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control nzErrorTip="Please fill out this address field" nzHasFeedback>
                        <input nz-input [nzSize]="inputSize" (input)="updateForm($event,'shipping','addressLine2')" formControlName="addressLine2" placeholder="Address line 2" />
                </nz-form-control>
            </nz-form-item>
            <!-- Address Lines  -->

            <!-- Phone Email  -->
            <div nz-row nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your Phone / Mobile No." nzHasFeedback>
                                <input nz-input [nzSize]="inputSize" formControlName="mobileNo" (input)="updateForm($event,'shipping','mobileNo')" placeholder="Phone / Mobile No." />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your Email" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="emailId" (input)="updateForm($event,'shipping','emailId')" placeholder="Email" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- Phone Email  -->

            <!-- City Zip  -->
            <div nz-row nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter name of your City" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="city" (input)="updateForm($event,'shipping','city')" placeholder="City" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your zipcode" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="zip" (input)="updateForm($event,'shipping','zip')" placeholder="Zip code" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- City Zip  -->

            <!-- State Country  -->
            <div nz-row nzJustify="space-between" nzAlign="middle">
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your State" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="state" (input)="updateForm($event,'shipping','state')" placeholder="State" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
                <div nz-col [nzSpan]="nestedColSize">
                    <nz-form-item>
                        <nz-form-control nzErrorTip="Please enter your Country" nzHasFeedback>
                            <input nz-input [nzSize]="inputSize" formControlName="country"(input)="updateForm($event,'shipping','country')" placeholder="Country" />
                        </nz-form-control>
                    </nz-form-item>
                </div>
            </div>
            <!-- State Country -->
        </form>
    </div>
</div>
