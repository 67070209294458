<div class="HostedFieldComponent border">
  <h3 class="h3-responsive">
    <span nz-icon nzType="credit-card" nzTheme="outline"></span> Card Details
  </h3>

  <div class="main-container hide-frame " #mainContainer>
    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="24" class="main-frame">
        <label>Card Number</label>
        <div id="card-number-container" class="hosted-field-input-container">
          <!-- <input  nz-input nzBorderless #cardNumber /> -->
        </div>
      </div>
    </div>
    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="8" class="main-frame">
        <label>Expiry Month</label>
        <div id="card-expiry-month-container" class="field-input-container">
          <nz-select nzShowSearch [(ngModel)]="expMonth" nzPlaceHolder="XX">
            <nz-option nzLabel="01" nzValue="01"></nz-option>
            <nz-option nzLabel="02" nzValue="02"></nz-option>
            <nz-option nzLabel="03" nzValue="03"></nz-option>
            <nz-option nzLabel="04" nzValue="04"></nz-option>
            <nz-option nzLabel="05" nzValue="05"></nz-option>
            <nz-option nzLabel="06" nzValue="06"></nz-option>
            <nz-option nzLabel="07" nzValue="07"></nz-option>
            <nz-option nzLabel="08" nzValue="08"></nz-option>
            <nz-option nzLabel="09" nzValue="09"></nz-option>
            <nz-option nzLabel="10" nzValue="10"></nz-option>
            <nz-option nzLabel="11" nzValue="11"></nz-option>
            <nz-option nzLabel="12" nzValue="12"></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col [nzSpan]="8" class="main-frame">
        <label>Expiry Year</label>
        <div id="card-expiry-year-container" class=" field-input-container">
          <nz-select nzShowSearch [(ngModel)]="expYear" nzPlaceHolder="XXXX">
            <nz-option *ngFor="let year of years" [nzLabel]="year+''" [nzValue]="year % 2000"></nz-option>
          </nz-select>
        </div>
      </div>
      <div nz-col [nzSpan]="8" class="main-frame">
        <label>CVV</label>
        <div id="card-cvv-container" class="hosted-field-input-container">
          <!-- <input nz-input nzBorderless #cardCVV/> -->
        </div>
      </div>
    </div>

    <div nz-row [nzGutter]="16">
      <div nz-col [nzSpan]="24" class="main-frame">
        <label>Card Holder's Name</label>
        <div id="card-name-container" class=" field-input-container">
          <input nz-input pattern="^((?:[A-Za-z]+ ?){1,3})$" minlength="3" nz-input placeholder="Card Holder Name"
            [(ngModel)]="cardHolderName" />
        </div>
      </div>
    </div>

    <label nz-checkbox [(ngModel)]="saveCardFlag" class="main-frame">
      <span class="save-card-txt">
        Save Card Details
      </span>
      <span nz-tooltip nzTooltipTitle="Securely store your data to ensure a rapid checkout next time" nz-icon
        nzType="question-circle" nzTheme="outline"></span>
    </label>
  </div>
  <div class="main-container show-frame" #skeletonLoader>
    <nz-skeleton [nzActive]="true" [nzParagraph]="{rows: 5}"></nz-skeleton>
  </div>


  <ng-container>
    <nz-input-group nzCompact>
      <input class="terminal-order-input" style="width:65%" nz-input type="text" style="display: none;"
        id="dummyElement" />
    </nz-input-group>
  </ng-container>
</div>
