<div class="payment-details-container">
  <div class="payment-container border p-20">
    <h3 class="h3-responsive">Payment Details <i nz-icon nzType="safety-certificate" nzTheme="twotone"
        [nzTwotoneColor]="'#43bd52'"></i></h3>
    <p class="my-30" *ngIf="cardVisibility">Please choose from one of the saved cards below in order to proceed with
      your payment.</p>

    <!-- cards -->

    <div nz-row nzJustify="center" *ngIf="cardVisibility" class="card-container">
      <div></div>
      <div nz-col [nzSpan]="colSize" *ngFor="let card of tokenCards; let i = index">
        <div
          class="payment-card {{card.issuer ==='MasterCard'?'card-1':'card-2'}} mt-20 {{card.id === selectedToken ? 'selected-card':''}}"
          (click)="selectPaymentCard(i)">
          <i class="selected-icon" nz-icon nzType="check-circle" nzTheme="twotone" [nzTwotoneColor]="'#43bd52'"></i>
          <div class="chip-wrapper ">
            <img src="../../../assets/sections/checkout/chip.png" class="img-fluid" alt="">
          </div>
          <div class="card-wrapper mt-20">
            {{card.maskedToken}}
          </div>
          <div class="expiry-wrapper">
            {{card.expiryDate.split('_')[1]}}/{{card.expiryDate.split('_')[0]}}
            <!-- <label class="ml-30">{{predefinedCards[0].cvv}}</label> -->
          </div>
          <div class="img-wrapper">
            <img src="../../../assets/sections/checkout/{{card.issuer}}.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>

    </div>
    <div *ngIf="cardVisibility && tokenCards?.length == 0" class="skeleton-element">
      <nz-skeleton-element nzType="button" [nzActive]="true" [nzSize]="'large'"
        [nzShape]="'default'"></nz-skeleton-element>
      <nz-skeleton-element nzType="button" [nzActive]="true" [nzSize]="'large'"
        [nzShape]="'default'"></nz-skeleton-element>
    </div>
    <div *ngIf="cardVisibility && tokenCards?.length == 0" class="skeleton-element">
      <nz-skeleton-element nzType="button" [nzActive]="true" [nzSize]="'large'"
        [nzShape]="'default'"></nz-skeleton-element>
      <nz-skeleton-element nzType="button" [nzActive]="true" [nzSize]="'large'"
        [nzShape]="'default'"></nz-skeleton-element>
    </div>
    <div nz-row *ngIf="!cardVisibility" class="flowers-row">
      <div nz-col [nzSpan]="24">
        <div class="payment-wrapper">
          <label nz-radio [ngModel]="true" class="payment-text-wrapper">Pay with {{companyName}}</label>
          <div class="payment-img-wrapper">
            <img [src]="whitelabelIcon" alt="" class="img-fluid" style="width: 100%;">
          </div>
        </div>

        <div class="paymentDetails">
          <p class="mt-10">Pay with our premium WL payments gateway for a quick, safe and secure transaction.</p>
        </div>
      </div>
    </div>
  </div>
</div>
