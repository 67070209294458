import { CurrencyService } from './../../services/currency.service';
import { Subscription } from 'rxjs';
import { storePaths } from 'src/app/StorePaths';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';
import { Component, EventEmitter, OnInit, Output, ViewEncapsulation, OnDestroy, Input } from '@angular/core';
import { Order, OrderService } from 'src/app/services/checkout.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.less'],
  encapsulation: ViewEncapsulation.None,

})
export class OrderDetailsComponent implements OnInit {

  orderObject!: Order;
  buttonStyle: object;
  currency = 'EUR';

  @Input() isIframe = false;
  @Input() isPayButtonDisable = false;

  constructor(
    private orderService: OrderService,
    private themeService: SwitchThemeService,
    private currencyService: CurrencyService,
  ) {
    const buttonColor = (window as any).whitelabelConfig?.buttonColor || '#43bd52';
    const buttonTextColor = (window as any).whitelabelConfig?.buttonTextColor || '#f2f8f9';
    this.buttonStyle = { backgroundColor: buttonColor, borderColor: buttonColor, color: buttonTextColor };
  }

  @Output() cryptoFinalPayment = new EventEmitter<object>();
  @Output() gamingFinalPayment = new EventEmitter<object>();
  @Output() fashionFinalPayment = new EventEmitter<object>();
  @Output() flowersFinalPayment = new EventEmitter<object>();



  ngOnInit(): void {
    this.getOrderServiceValues();
    this.currency = this.currencyService.selectedCurrency;
    if (this.isIframe) {
      this.finalPayment();
    }
  }

  getOrderServiceValues(): void {
    this.orderObject = this.orderService.orderObject;
  }

  finalPayment(currentTheme?: string): void {
    this.getOrderServiceValues();
    console.log(this.themeService.currentTheme, this.orderObject, JSON.stringify(this.orderObject));
    localStorage.setItem('orderObject', JSON.stringify(this.orderObject));
    localStorage.setItem('addressObject', JSON.stringify(this.orderService.addressObject));

    if ([storePaths.flowers, 'card'].includes(this.themeService.currentTheme)) {
      this.flowersFinalPayment.emit({
        address: this.orderService.addressObject,
        order: this.orderObject
      });
    } else {

      this.fashionFinalPayment.emit({
        address: this.orderService.addressObject,
        order: this.orderObject,
        card: this.orderService.selectedCard
      });
    }

  }
}
