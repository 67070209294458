import { trigger, state, style, transition, animate } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.less'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(300)
      ]),
      transition(':leave',
        animate(300, style({ opacity: 0 })))
    ])
  ]
})
export class LoaderComponent implements OnInit {

  @Input() showBackdrop!: boolean;
  @Input() showInnerLoader!: boolean;
  @Input() showSuccessLoader!: boolean;
  @Input() showFailLoader!: boolean;
  constructor() { }

  ngOnInit(): void {
  }

}
