import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { WhitelabelConfig } from '../whitelabel';

@Injectable({
  providedIn: 'root'
})
export class WhitelabelService {
  public config: WhitelabelConfig | {};
  public favIcon: HTMLLinkElement | null;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.config = {};
    this.favIcon = this.document.querySelector('#appIcon');
  }
  public setWhitelabelJSON(config: WhitelabelConfig): void {
    this.config = config;
    (window as any).whitelabelConfig = config;
    if (this.favIcon) { this.favIcon.href = config.icons; }
  }
  public getWhitelabelConfig(): WhitelabelConfig | {} {
    return this.config;
  }
}
