<div [style]="{'padding': '0' + ( gutterSize / 2 ) + 'px'}">
    <div nz-row [nzGutter]="gutterSize" nzAlign="middle" class="mb-30">
        <div nz-col nzSpan="24">
            <h2 class="h2-responsive mb-0 text-center">
                <strong>Zapper Coins</strong>
            </h2>
        </div>
    </div>
    <div nz-row [nzGutter]="gutterSize" nzAlign="middle" nzJustify="space-between">
      <div style="cursor: pointer;" nz-col [nzSpan]="colSize" *ngFor="let column of columns" #plan (click)="changePlan(plan)" [attr.data-plan-value]="column.plan" class="text-center pt-10 columns">
              <h3 class="h3-responsive mb-20">{{column.amount}}</h3>
              <div class="text-center">
                <img [src]="column.imageSrc" class="img-fluid mb-20" alt="">
              </div>
      </div>
    </div>
    <div nz-row [nzGutter]="gutterSize" nzAlign="middle" style="margin-top: 60px;" class="second-row">
        <div nz-col class="gutter-row" [nzSpan]="colSize2">
            <img [src]="gamingBigImg" class="img-fluid gaming-big-img" alt="">
          </div>
          <div nz-col class="gutter-row second-col" [nzSpan]="colSize2" >
            <div class="mw-x-600">
              <h4 class="h4-responsive">This currency will enable you to access exclusive features and skins within our world. This is your chance to take your gaming experience to a whole other level!</h4>
              <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
                <nz-form-item>
                  <nz-form-control nzErrorTip="Please select a value between 1 and 2000!">
                    <nz-input-group>
                      <nz-slider (ngModelChange)="updateImage()" [nzMin]="1" [nzMax]="2000" [nzStep]="10" [nzTipFormatter]="formatter" formControlName="cryptoAmount"></nz-slider>
                    </nz-input-group>
                  </nz-form-control>
                </nz-form-item>
                <div nz-row [nzGutter]="gutterSize" nzAlign="middle">
                  <div nz-col [nzSpan]="nestedColSize">
                    <div nz-row>
                      <div nz-col [nzSpan]="12" style="padding-right: 10px;">
                        {{getCryptoAmount()}}
                      </div>
                      <div nz-col [nzSpan]="12" class="text-right">
                        <span nz-tooltip nzTooltipTitle="Coins" style="cursor: pointer;">Coins</span>
                      </div>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="nestedColSize" >
                    <div nz-row nzAlign="middle">
                      <div nz-col [nzSpan]="12" style="padding-right: 10px;">
                        {{getPayableAmount()}}
                      </div>
                      <div nz-col [nzSpan]="12" class="text-right">
                        <nz-select style="font-size:inherit; width:80px;" nzShowSearch [ngModelOptions]="{standalone: true}" [(ngModel)]="currency" (ngModelChange)="currencyChange()" [nzOptions]="currencyList" nzBorderless></nz-select>
                      </div>
                    </div>
                  </div>
                  <div nz-col [nzSpan]="nestedColSize" class="text-right">
                    <button [ngStyle]="buttonStyle" class="grad-button" type="button" (click)="gotoCheckout()" nz-button nzType="primary" [nzSize]="payBtnSize" nzShape="round"><i nz-icon nzType="send" nzTheme="outline"></i>Buy Now</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
    </div>
</div>
