import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.less']
})
export class FooterComponent implements OnInit {

  year = new Date().getFullYear();
  companyName: string;
  shortAddress: string;

  constructor() {
    const whitelabelConfig = (window as any).whitelabelConfig;
    this.companyName = whitelabelConfig?.companyName || 'WLPayments B.V.';
    this.shortAddress = whitelabelConfig?.shortAddress || 'Amsterdam, KvK nr. 75952408';
  }

  ngOnInit(): void {
  }

}
