import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { storePaths } from '../StorePaths';

@Injectable({
  providedIn: 'root'
})
export class SwitchThemeService {

  body;
  private _currentTheme!: string;

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.body = this.document.body;
    this.fashionTheme();

  }

  private classes = storePaths;

  clearClasses(): void {
    const classList = this.body.classList;
    classList.remove(this.classes.crypto);
    classList.remove(this.classes.gaming);
    classList.remove(this.classes.fashion);
    classList.remove(this.classes.flowers);
    classList.remove('success');
    classList.remove('fail');
  }

  set currentTheme(theme: string) {
    this._currentTheme = theme;
  }

  get currentTheme(): string {
    return this._currentTheme;
  }

  addClass(className: string): void {
    this.body.classList.add(className);
  }

  cryptoTheme(): void {
    this.clearClasses();
    this.addClass(this.classes.crypto);
    this.currentTheme = this.classes.crypto;
  }
  gamingTheme(): void {
    this.clearClasses();
    this.addClass(this.classes.gaming);
    this.currentTheme = this.classes.gaming;
  }
  fashionTheme(): void {
    this.clearClasses();
    this.addClass(this.classes.fashion);
    this.currentTheme = this.classes.fashion;
  }
  flowersTheme(): void {
    this.clearClasses();
    this.addClass(this.classes.flowers);
    this.currentTheme = this.classes.flowers;
  }
  clearTheme(): void {
    this.clearClasses();
    this.currentTheme = '';
  }
}
