import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { storePaths } from 'src/app/StorePaths';
import { FashionComponent } from 'src/app/product-pages/fashion/fashion.component';

@Component({
  selector: 'app-store-type',
  templateUrl: './store-type.component.html',
  styleUrls: ['./store-type.component.less']
})
export class StoreTypeComponent implements OnInit {

  @Input() stripeTheme: 'light' | 'dark' = 'light';
  @Input() storeTypeName = '';
  @Input() imgSrc = '';
  @Input() redirectUrl = '';
  @Input() amount: number = 0;
  @Input() bgColor?:string= '#ffffff'
  @ViewChild(FashionComponent) fashionComponent!: FashionComponent

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  onClickHandler(): void {
    this.fashionComponent.gotoCheckout(this.amount?.toString());
    this.router.navigate(['/', this.redirectUrl]);

  }

}

export interface StoreTypeInterface {
  stripeTheme: 'light' | 'dark';
  storeTypeName: string;
  imgSrc: string;
  redirectUrl: string;
  amount: number;
  backgroundColor?:string,
}
