<nz-layout class="layout">
  <nz-header>
    <app-header></app-header>
  </nz-header>
  <nz-content >
    <nz-breadcrumb>
    </nz-breadcrumb>
    <div class="inner-content" [@routeAnimations]="prepareRoute(outlet)">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>
  </nz-content>
  <nz-footer>
    <app-footer></app-footer>
  </nz-footer>
</nz-layout>  