<div class="gallery-container">
    <nz-carousel [nzEffect]="effect" nzDots="false" [nzEnableSwipe]="enableSwipe" class="p-20 pt-0" >
        <div nz-carousel-content *ngFor="let image of images" class="text-center">
            <img [src]="image.src"  class="img-fluid" alt="" (click)="showModal()"/>
        </div>
    </nz-carousel>
    <button (click)="prev()" [nzSize]="controlsSize" [disabled]="galleryPrevDisable" class="controls left" nz-button nzShape="circle">
        <i nz-icon nzType="left"></i>
    </button>
    <button (click)="next()" [nzSize]="controlsSize" [disabled]="galleryNextDisable" class="controls right" nz-button nzShape="circle">
        <i nz-icon nzType="right"></i>
    </button>
    <nz-modal [(nzVisible)]="isModalVisible" nzWrapClassName="imgViewerModal" nzWidth="95%" nzFooter="null" nzTitle="" (nzOnCancel)="handleCancel()" >
        <ng-container>
            <nz-carousel #modalCarousel *ngIf="isModalCarouselVisible" nzDots="false" [nzEffect]="effect" [nzEnableSwipe]="enableSwipe"  class="p-0" >
                <div nz-carousel-content *ngFor="let image of images" class="text-center" >
                    <img [src]="image.src"  class="img-fluid" alt=""/>
                </div>
            </nz-carousel>
            <button (click)="prev()" [disabled]="galleryPrevDisable" [nzSize]="controlsSize" class="controls left" nzSize="large" nz-button nzShape="circle">
                <i nz-icon nzType="left"></i>
            </button>
            <button (click)="next()" [disabled]="galleryNextDisable" [nzSize]="controlsSize" class="controls right" nzSize="large" nz-button nzShape="circle">
                <i nz-icon nzType="right"></i>
            </button>
        </ng-container>
    </nz-modal>
</div>
