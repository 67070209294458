<div class="fail-container">
    <div nz-row nzJustify="center" nzAlign="middle" class="mt-30">
        <div nz-col [nzSpan]="colSize" >
            <div class="p-20 summary-1" style="height: 100%;">
                <div class="fail-wrapper">
                    <h2 class="h2-responsive mb-0" *ngIf="isFailed">Oops! Your Order isn't processed properly.</h2>
                    <h2 class="h2-responsive mb-0" *ngIf="isCancelled">It seems your payment isn't processed completely.</h2>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" *ngIf="showLoader">
                        <circle class="path circle" fill="none" stroke="#e74c3c" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <line class="path line" fill="none" stroke="#e74c3c" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="34.4" y1="37.9" x2="95.8" y2="92.3"/>
                        <line class="path line" fill="none" stroke="#e74c3c" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" x1="95.8" y1="38" x2="34.4" y2="92.2"/>
                    </svg>
                </div>
                <h3 class="h3-responsive mt-30">Transaction Summary</h3>
                <p style="font-size: 16px;">Your Payment has been declined with transaction Id: {{txnRef}}.</p>
                <div class="transaction-details">
                    <p>Transaction Time:</p> <p style="text-align: end;"><span> {{time}} </span></p>
                    <p>Transaction Date:</p> <p style="text-align: end;"><span> {{date}} </span></p>
                </div>
                <h5 class="h5-responsive"><strong>Shipping Address for this order:</strong></h5>
                <div>
                    <p>
                        {{addressObject.firstName}} {{addressObject.lastName}} <br>
                        {{addressObject.addressLine1}}, <br>
                        {{addressObject.addressLine2}}, {{addressObject.city}} <br>
                        {{addressObject.state}} - {{addressObject.zip}} <br>
                        {{addressObject.country}} ({{addressObject.mobileNo}})
                    </p>
                </div>
            </div>
        </div>
        <div nz-col [nzSpan]="colSize">
            <div class="p-20 summary-2">
                <h3 class="h3-responsive"><i nz-icon nzType="shopping-cart" nzTheme="outline"></i> Order Summary</h3>
                <div class="mt-30">
                    <!-- <p style="width: 100%;margin-bottom: 15px;" >OrderId: #00{{txnRef | slice:14:txnRef.length}}</p> -->
                    <div class="details-wrapper">
                        <p>Product name:</p> <p style="text-align: end;"><span>{{orderObject.name | titlecase}}</span></p>
                        <p>Quantity:</p> <p style="text-align: end;"><span>{{orderObject.quantity}}</span></p>
                        <p>Product price:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
                    </div>
                    <nz-divider></nz-divider>
                    <div>
                        <p>Sub Total:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
                        <p>Tax:</p> <p style="text-align: end;"><span>0.00</span></p>
                        <p>Shipping:</p> <p style="text-align: end;"><span>0.00</span></p>
                        <p>Discount:</p> <p style="text-align: end;"><span>0.00</span></p>
                    </div>
                    <nz-divider></nz-divider>
                    <div class="final-price-wrapper">
                        <p>Grand Total:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
