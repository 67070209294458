import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';
import { FooterComponent } from './footer/footer.component';
import { HeaderComponent } from './header/header.component';
import { HomePageComponent } from './home-page/home-page.component';
import { StoreTypeComponent } from './home-page/store-type/store-type.component';
import { FashionComponent } from './product-pages/fashion/fashion.component';
import { GamingComponent } from './product-pages/gaming/gaming.component';
import { CryptoComponent } from './product-pages/crypto/crypto.component';
import { FlowersComponent } from './product-pages/flowers/flowers.component';
import { GalleryComponent } from './gallery/gallery.component';
import { CheckoutComponent } from './product-pages/crypto/checkout/checkout.component';
import { AddressDetailComponent } from './product-pages/address-detail/address-detail.component';
import { PaymentDetailsComponent } from './product-pages/payment-details/payment-details.component';
import { OrderDetailsComponent } from './product-pages/order-details/order-details.component';
import { GamingCheckoutComponent } from './product-pages/gaming/gaming-checkout/gaming-checkout.component';
import { FashionCheckoutComponent } from './product-pages/fashion/fashion-checkout/fashion-checkout.component';
import { FlowersCheckoutComponent } from './product-pages/flowers/flowers-checkout/flowers-checkout.component';
import { SuccessComponent } from './success/success.component';
import { FailComponent } from './fail/fail.component';
import { LoaderComponent } from './loader/loader.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { WhitelabelService } from './services/whitelabel.service';
import { initializeAppFactory } from './Cards';
import { GamingCheckoutTwoComponent } from './product-pages/gaming/gaming-checkout-2/gaming-checkout-2.component';
import { HostedFieldComponent } from 'src/app/product-pages/hosted-field/hosted-field.component';
// import { initializeAppFactory } from './INITIALIZE_TOKEN';

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    HeaderComponent,
    HomePageComponent,
    StoreTypeComponent,
    FashionComponent,
    GamingComponent,
    CryptoComponent,
    FlowersComponent,
    GalleryComponent,
    CheckoutComponent,
    AddressDetailComponent,
    PaymentDetailsComponent,
    OrderDetailsComponent,
    GamingCheckoutComponent,
    FashionCheckoutComponent,
    FlowersCheckoutComponent,
    SuccessComponent,
    FailComponent,
    LoaderComponent,
    PageNotFoundComponent,
    GamingCheckoutTwoComponent,
    HostedFieldComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DemoNgZorroAntdModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [HttpClient, WhitelabelService],
      multi: true,
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
