<div class="success-container">
    <div nz-row nzJustify="center" nzAlign="middle" class="mt-30">
        <div nz-col [nzSpan]="colSize" class="">
            <div class="p-20 summary-1">
                <div class="success-wrapper">
                    <h2 class="h2-responsive mb-0">Thank you! Your Order has been Confirmed.</h2>
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 130.2 130.2" *ngIf="showLoader">
                        <circle class="path circle" fill="none" stroke="#73AF55" stroke-width="6" stroke-miterlimit="10" cx="65.1" cy="65.1" r="62.1"/>
                        <polyline class="path check" fill="none" stroke="#73AF55" stroke-width="6" stroke-linecap="round" stroke-miterlimit="10" points="100.2,40.2 51.5,88.8 29.8,67.5 "/>
                    </svg>
                </div>
                <h3 class="h3-responsive mt-30">Transaction Summary</h3>
                <p style="font-size:16px;">Your Payment has been successful with transacion Id: {{txnRef}}.</p>
                <div class="transaction-details">
                    <p>Transaction Time:</p> <p style="text-align: end;"><span> {{time}} </span></p>
                    <p>Transaction Date:</p> <p style="text-align: end;"><span> {{date}} </span></p>
                    <!-- <p>Transaction Id:</p> <p style="text-align: end;"><span>{{}}</span></p> -->
                </div>
                <h3 class="h3-responsive">This order will be shipped to :</h3>
                <div>
                    <p>
                        {{addressObject.firstName}} {{addressObject.lastName}} <br>
                        {{addressObject.addressLine1}}, <br>
                        {{addressObject.addressLine2}}, {{addressObject.city}} <br>
                        {{addressObject.state}} - {{addressObject.zip}} <br>
                        {{addressObject.country}} ({{addressObject.mobileNo}})
                    </p>
                    <p></p>
                </div>
            </div>
        </div>
        <div nz-col [nzSpan]="colSize">
            <div  class="p-20 summary-2">
                <h3 class="h3-responsive"><i nz-icon nzType="shopping-cart" nzTheme="outline"></i> Order Summary</h3>
                <div class="mt-30">
                    <p style="width: 100%;margin-bottom: 15px;" >OrderId: #00{{txnRef | slice:18:txnRef.length}}</p>
                    <div class="details-wrapper">
                        <p>Product name:</p> <p style="text-align: end;"><span>{{orderObject.name | titlecase}}</span></p>
                        <p>Quantity:</p> <p style="text-align: end;"><span>{{orderObject.quantity}}</span></p>
                        <p>Product price:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
                    </div>
                    <nz-divider></nz-divider>
                    <div>
                        <p>Sub Total:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
                        <p>Tax:</p> <p style="text-align: end;"><span>0.00</span></p>
                        <p>Shipping:</p> <p style="text-align: end;"><span>0.00</span></p>
                        <p>Discount:</p> <p style="text-align: end;"><span>0.00</span></p>
                    </div>
                    <nz-divider></nz-divider>
                    <div class="final-price-wrapper">
                        <p>Grand Total:</p> <p style="text-align: end;"><span>{{orderObject.price | currency:currency}}</span></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
