import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  public currencyList = [
    {
      label: 'AFN',
      value: 'AFN'
    },
    {
      label: 'ARS',
      value: 'ARS'
    },
    {
      label: 'AUD',
      value: 'AUD'
    },
    {
      label: 'BGN',
      value: 'BGN'
    },
    {
      label: 'BRL',
      value: 'BRL'
    },
    {
      label: 'CAD',
      value: 'CAD'
    },
    {
      label: 'CHF',
      value: 'CHF'
    },
    {
      label: 'CLP',
      value: 'CLP'
    },
    {
      label: 'CNY',
      value: 'CNY'
    },
    {
      label: 'COP',
      value: 'COP'
    },
    {
      label: 'CYP',
      value: 'CYP'
    },
    {
      label: 'CZK',
      value: 'CZK'
    },
    {
      label: 'DKK',
      value: 'DKK'
    },
    {
      label: 'EGP',
      value: 'EGP'
    },
    {
      label: 'EUR',
      value: 'EUR'
    },
    {
      label: 'GBP',
      value: 'GBP'
    },
    {
      label: 'HKD',
      value: 'HKD'
    },
    {
      label: 'HRK',
      value: 'HRK'
    },
    {
      label: 'HUD',
      value: 'HUD'
    },
    {
      label: 'HUF',
      value: 'HUF'
    },
    {
      label: 'IDR',
      value: 'IDR'
    },
    {
      label: 'ILS',
      value: 'ILS'
    },
    {
      label: 'INR',
      value: 'INR'
    },
    {
      label: 'ISK',
      value: 'ISK'
    },
    {
      label: 'JPY',
      value: 'JPY'
    },
    {
      label: 'KRW',
      value: 'KRW'
    },
    {
      label: 'KWD',
      value: 'KWD'
    },
    {
      label: 'KZT',
      value: 'KZT'
    },
    {
      label: 'LAK',
      value: 'LAK'
    },
    {
      label: 'LBP',
      value: 'LBP'
    },
    {
      label: 'MXN',
      value: 'MXN'
    },
    {
      label: 'MYR',
      value: 'MYR'
    },
    {
      label: 'NGN',
      value: 'NGN'
    },
    {
      label: 'NOK',
      value: 'NOK'
    },
    {
      label: 'NZD',
      value: 'NZD'
    },
    {
      label: 'PHP',
      value: 'PHP'
    },
    {
      label: 'PKR',
      value: 'PKR'
    },
    {
      label: 'PLN',
      value: 'PLN'
    },
    {
      label: 'PYG',
      value: 'PYG'
    },
    {
      label: 'QAR',
      value: 'QAR'
    },
    {
      label: 'RON',
      value: 'RON'
    },
    {
      label: 'RSD',
      value: 'RSD'
    },
    {
      label: 'RUB',
      value: 'RUB'
    },
    {
      label: 'SEK',
      value: 'SEK'
    },
    {
      label: 'SGD',
      value: 'SGD'
    },
    {
      label: 'THB',
      value: 'THB'
    },
    {
      label: 'TRY',
      value: 'TRY'
    },
    {
      label: 'TWD',
      value: 'TWD'
    },
    {
      label: 'UAH',
      value: 'UAH'
    },
    {
      label: 'USD',
      value: 'USD'
    },
    {
      label: 'UYU',
      value: 'UYU'
    },
    {
      label: 'ZAR',
      value: 'ZAR'
    }
  ];

  public selectedCurrency: string;

  constructor() {
    const curr = (window as any).whitelabelConfig.currency;
    this.selectedCurrency = curr ? curr : 'EUR';
  }

  public changeCurrency(curr: string): void {
    this.selectedCurrency = curr;
  }

}
