<app-loader [showBackdrop]="showBackdrop" [showInnerLoader]="showInnerLoader" [showSuccessLoader]="false"
  [showFailLoader]="false"></app-loader>
<div [style]="{'padding': '0 ' + ( gutterSize / 2 ) + 'px'}" class="checkout-container">
  <div nz-row nzAlign="middle" [nzGutter]="gutterSize">
    <div nz-col class="gutter-row" [nzSpan]="24">
      <h1 class="h1-responsive">Checkout Page</h1>
      <nz-divider></nz-divider>
    </div>
  </div>
  <div nz-row nzAlign="top" [nzGutter]="16">
    <div nz-col class="gutter-row p-20" [nzSpan]="colSize">
      <app-address-detail></app-address-detail>
    </div>
    <div nz-col class="gutter-row p-20" [nzSpan]="colSize">
      <app-hosted-field *ngIf="isHostedField" (isPayButtonDisable)="payButtonHandler($event)"></app-hosted-field>
      <app-payment-details *ngIf="!isHostedField" [cardVisibility]="false"></app-payment-details>
    </div>
    <div nz-col class="gutter-row p-20" [nzSpan]="colSize">
      <app-order-details (flowersFinalPayment)="checkoutPayment($event)" [isPayButtonDisable]="isPayButtonDisable"></app-order-details>
    </div>
  </div>
  <form method="POST" id="hppForm" [action]="actionLink" #hppForm>
    <input type="hidden" name="data" #hiddenInput [value]="responseValue">
    <button type="submit" #submitBtn style="visibility: hidden;"></button>
  </form>
</div>
