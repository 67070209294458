<div [style]="{'padding': '0' + ( gutterSize / 2 ) + 'px'}" class="crypto-container">
  <div nz-row [nzGutter]="gutterSize" nzAlign="middle">
    <div nz-col class="gutter-row" [nzSpan]="colSize">
      <img src="../../../assets/sections/crypto/cryptocurrency.jpg" class="img-fluid" alt="">
    </div>
    <div nz-col class="gutter-row second-col" [nzSpan]="colSize">
      <div class="mw-x-600">
        <h1 class="h1-responsive">Buy your first Crypt-Coin with your debit or credit Card today!</h1>
        <p>Investors from all across the globe have made millions from investing in our currency. So what are you
          waiting for, buy your first Crypt-coin today and then sit back and watch the profits come rolling in.</p>
        <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm()">
          <nz-form-item>
            <nz-form-control nzErrorTip="Please select a value between 0.01 and 2!">
              <nz-input-group>
                <nz-slider [nzMin]="0.01" [nzMax]="2" [nzStep]="0.01" [nzTipFormatter]="formatter"
                  formControlName="cryptoAmount"></nz-slider>
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
          <div nz-row [nzGutter]="gutterSize" nzAlign="middle">
            <div nz-col [nzSpan]="isScreenSm ? 12 : 8" [ngClass]="{'ant-form-item': isScreenSm}">
              <div nz-row>
                <div nz-col [nzSpan]="12" style="padding-right: 10px;">
                  {{getCryptoAmount()}}
                </div>
                <div nz-col [nzSpan]="12" class="text-right">
                  <span nz-tooltip nzTooltipTitle="Crypt-Coin" style="cursor: pointer;">C-Coin</span>
                </div>
              </div>
            </div>
            <div nz-col [nzSpan]="isScreenSm ? 12 : 8" [ngClass]="{'ant-form-item': isScreenSm}">
              <div nz-row nzAlign="middle" >
                <div nz-col [nzSpan]="12" style="padding-right: 10px;">
                  {{getPayableAmount()}}
                </div>
                <div nz-col [nzSpan]="12" class="text-right">
                  <nz-select nzShowSearch style="font-size:inherit; width:80px;" [(ngModel)]="currency"
                    [ngModelOptions]="{standalone: true}" (ngModelChange)="currencyChange()" [nzOptions]="currencyList"
                    nzBorderless></nz-select>
                </div>
              </div>
            </div>
            <div nz-col [nzSpan]="isScreenSm ? 24 : 8" class="text-right">
              <button class="grad-button" (click)="gotoCheckout()" type="button" nz-button nzType="primary"
                [nzSize]="payBtnSize" nzShape="round" [ngStyle]="buttonStyle"><i nz-icon nzType="send"
                  nzTheme="outline"></i>Buy Now</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
