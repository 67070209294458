import { PaymentService } from 'src/app/services/payment.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, ElementRef, ViewChild, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';
import { Router } from '@angular/router';
import { HostedFieldComponent } from 'src/app/product-pages/hosted-field/hosted-field.component';
import { storePaths } from 'src/app/StorePaths';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';

@Component({
  selector: 'app-flowers-checkout',
  templateUrl: './flowers-checkout.component.html',
  styleUrls: ['./flowers-checkout.component.less']
})
export class FlowersCheckoutComponent implements OnInit, OnDestroy, AfterViewInit {

  gutterSize = 16;
  colSize = 8;
  actionLink = '';
  responseValue = '';
  showBackdrop = false;
  showInnerLoader = true;
  isPayButtonDisable = false;
  isHostedField!: boolean;
  private breakPointSubscription: Subscription;
  @ViewChild('hppForm') hppForm!: ElementRef;
  @ViewChild(HostedFieldComponent) hostedFieldComponent!: HostedFieldComponent;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private paymentService: PaymentService,
    private router: Router,
    private themeService: SwitchThemeService,

  ) {
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 1024px)').subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 24 : 8;
    });
  }
  unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }
  ngOnInit(): void {
    this.titleService.setTitle(pageTitles.checkout);
    this.isHostedField = this.router.url == '/zero-auth-checkout';
    this.themeService.currentTheme = 'card'
  }

  ngOnDestroy(): void {
    this.unsubscribe(this.breakPointSubscription);
  }

  ngAfterViewInit(): void {
    console.log(this.hppForm.nativeElement.action);
  }

  payButtonHandler(flag: boolean) {
    this.isPayButtonDisable = flag;
  }

  async checkoutPayment(eventObject: any): Promise<void> {
    let token;
    this.showBackdrop = true;
    let apiType = 'HPP'
    let conditionFlag = this.router.url === `/${storePaths.zeroAuth}-checkout`
    if (conditionFlag) {
      token = (await this.hostedFieldComponent.payNowEventHandler()).tokenID;
      apiType = 'zeroAuth'

    }
    this.showBackdrop = true;
    if (!conditionFlag) {
      this.paymentService.prepareRequestObject({ ...eventObject, apiType: apiType });
    }
    eventObject.card = { tokenID: token };
    this.paymentService.doPayment({ ...eventObject, apiType }).subscribe(response => {
      this.actionLink = response.action;
      this.responseValue = response.value;
      setTimeout(() => this.hppForm.nativeElement.submit(), 50);
    },
      error => {
        console.log(error);
      });
  }
}
