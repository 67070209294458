import { Router } from '@angular/router';
import { PaymentService } from './../../../services/payment.service';
import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, OnInit, OnDestroy, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { pageTitles } from 'src/app/PageTitles';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { HostedFieldComponent } from 'src/app/product-pages/hosted-field/hosted-field.component';
import { PaymentDetailsComponent } from 'src/app/product-pages/payment-details/payment-details.component';
import { SwitchThemeService } from 'src/app/services/switch-theme.service';

declare const window: any;

@Component({
  selector: 'app-fashion-checkout',
  templateUrl: './fashion-checkout.component.html',
  styleUrls: ['./fashion-checkout.component.less'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500)
      ]),
      transition(':leave',
        animate(500, style({ opacity: 0 })))
    ])
  ]
})
export class FashionCheckoutComponent implements OnInit, OnDestroy {

  gutterSize = 16;
  colSize = 8;
  isIFrameModalVisible = false;
  showBackdrop = false;
  showInnerLoader = true;
  isPayButtonDisable = false;
  scriptPath = 'assets/iframe_sdk.js';

  @ViewChild(PaymentDetailsComponent) paymentDetailsComponent!: PaymentDetailsComponent;

  private breakPointSubscription: Subscription;
  @HostListener('window:message', ['$event'])
  onMessage(event: any): void {
    let dataArray
    if (event && event.data && event.data.split) {
      dataArray = event?.data?.split(' ');
      if (dataArray[0] === 'fail') {
        this.router.navigate(['/fail'], { queryParams: { txnRef: dataArray[1] } });
      }
      else if (dataArray[0] === 'success') {
        this.router.navigate(['/success'], { queryParams: { txnRef: dataArray[1] } });
      }
    }
  }

  constructor(
    private breakpointObserver: BreakpointObserver,
    private titleService: Title,
    private paymentService: PaymentService,
    private router: Router,
    private themeService: SwitchThemeService,

  ) {
    this.breakPointSubscription = this.breakpointObserver.observe('(max-width: 1024px)').subscribe(result => {
      this.gutterSize = result.matches ? 16 : 48;
      this.colSize = result.matches ? 24 : 8;
    });
  }

  ngOnInit(): void {
    this.titleService.setTitle(pageTitles.checkout);
    this.loadScript();
    this.themeService.fashionTheme();
  }

  ngOnDestroy(): void {
    this.unsubscribe(this.breakPointSubscription);
  }

  unsubscribe(subscription: Subscription): void {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  payButtonHandler(flag: boolean) {
    this.isPayButtonDisable = flag;
  }

  loadScript(): void {
    const body = document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = this.scriptPath;
    script.async = false;
    script.defer = true;
    body.appendChild(script);
  }

  closeModal(): void {
    this.isIFrameModalVisible = false;
  }

  async checkoutPayment(eventObject: any): Promise<void> {
    let token;
    this.showBackdrop = true;
    if (this.router.url === '/recurring-payment-checkout') {
      token = this.paymentDetailsComponent.getSelectedToken();
    }
    eventObject.card = { tokenID: token };
    this.paymentService.doPayment({ ...eventObject, apiType: 'sync' }).subscribe(response => {

      if (response.response) {
        response = response.response;
        const txnRef = response.txnReference;
        const status = response.OriginalTxnStatusCode;
        if (status === 200 || status === 201) {
          this.router.navigate(['/success'], { queryParams: { txnRef } });
        }
        else {
          this.router.navigate(['/fail'], { queryParams: { txnRef } });
        }
      }
      else {
        console.log(response);
      }
    }, errorRes => {
      console.log(errorRes);
    });
  }

  loadedIframe(): void {
    console.log('iframe loaded');
  }
}
