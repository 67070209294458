<div [style]="{'padding': '0' + ( gutterSize / 2 ) + 'px'}" class="homepage-container">
  <div nz-row [nzGutter]="gutterSize">
    <div nz-col class="gutter-row content-header">
      <h1 nz-typography>
        Paysafe payment Demo
      </h1>
      <p>In order for you to get better insight into how 3DS 2 functions we have created a demo website for you to
        explore. Each of the categories below, i.e. Zero-Auth Payment, Recurring payment, Hosted Page Payment represents a different
        payment flow.</p>
    </div>
  </div>

  <div nz-row [nzGutter]="gutterSize" [nzJustify]="'center'">
    <div nz-col class="gutter-row" [nzSpan]="colSize" *ngFor="let section of sections">
      <app-store-type [stripeTheme]="section.stripeTheme" [storeTypeName]="section.storeTypeName"
        [imgSrc]="section.imgSrc" [redirectUrl]="section.redirectUrl" [amount]="section.amount"
        [bgColor]="section.backgroundColor"></app-store-type>
    </div>
  </div>
</div>
